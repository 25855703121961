.title-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100px;
}

.row-style{
    padding-top: 4%;
}

.summary-col {
    border: 1px solid #ccc;
    padding: 50px;
    border-radius: 5px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.summary-content{
    text-align: center;
}

.date-selection-container {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    gap: 16px;

    padding: 20px;
    background: #f9f9f9;
    border-radius: 8px;
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1);
    width: 100%;
    max-width: 100%;
    margin-bottom: 3%;
    flex-wrap: wrap;
}

.tags-container {
    display: flex;
    gap: 8px;
    flex-wrap: wrap;
}

.date-picker-container {
    display: flex;
    align-items: center;
    gap: 12px;
}
