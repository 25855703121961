.edit {
    display: block;
    margin-left: auto;
    margin-right: 0;
}

.title{ 
    font-size: large;
}

.divider{
    padding-top: 35px;
}

.ant-divider-inner-text{
    color: #1677ff;
}