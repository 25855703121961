.header-container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 10px;
}

.text {
    font-weight: normal;
}

.editDeal {
    margin-left: auto;
}

.page-container {
    display: flex;
    justify-content: space-between;
    padding: 20px;
    gap: 20px;
    align-items: flex-start;
}

.left-section {
    width: 65%;
    padding: 15px;
}

.right-section {
    width: 35%;
    background-color: #ffffff;
    padding: 15px;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    height: auto;
}


h1 {
    margin: 0;
}

.text{
    font-weight: normal;
    font-size: medium;
}

.border {
    border: 1px solid gray;
    border-radius: 5px;
    padding: 5px;
}

.inline-input-container {
    display: flex;
    align-items: center;
}

.label {
    margin: 0;
    font-size: 16px;
}

.inline-input-container .ant-form-item {
    margin-bottom: 0;
    flex: 1;
}

.submitButton{
    margin-top: 25px;
}

.stepButtons{
    margin-bottom: 5%
}

/*Deal Step CSS*/

/* DealStep.css */

/* Current step container */
.current-step-container {
    background-color: #f0f8ff;
    padding: 5px;
    border-radius: 4px;
}

/* Current step text */
.current-step-text {
    font-weight: bold;
    color: #1890ff;
}

/* Arrow indicator for the current step */
.arrow-indicator {
    color: #1890ff;
    font-weight: bold;
}

/* Past step text */
.past-step-text {
    text-decoration: line-through;
    color: #888;
}

/* Status label */
.status-label {
    color: #888;
    font-size: 12px;
    margin-bottom: 5px;
}
