.ant-segmented {
    width: 400px !important;
    height: auto !important;
}

.ant-segmented-item {
    flex: 1;
    height: 50px !important;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 18px !important;
    padding: 10px 20px !important;
    font-weight: bold;
}

.ant-segmented-item-selected {
    height: 50px !important; /* Ensure selected item height matches */

}