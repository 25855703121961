h2.navTitle{
    color:white;
    line-height: inherit;
    margin-bottom: 0;
}

.container {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 10px;
    background-color: #f2f2f2;
    border: 1px solid #ddd;
    border-radius: 5px;
}

.total-number {
    font-size: 16px;
    color: #333;
}

.action-button{
    text-align: center;
    border-radius: 5px;
    font-weight: bold;
    padding: 0 20px;
    height: 40px;
    line-height: 40px;
}

.action-button:hover {
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}
