* {
    text-align: left;
    text-decoration: none;
}

.layout {
    padding-top: 60px;
    height: auto;
    background-color: white !important;
}

header {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    padding: 10px;
    z-index: 1000;
}

.header-parent {
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    height: 64px;
}

.header-left {
    position: absolute;
    left: 20px;
    display: flex;
    align-items: center;
    cursor: pointer;
    height: 64px;
}

.header-right {
    position: absolute;
    right: 20px;
    display: flex;
    align-items: center;
    height: 64px;
}

.navTitle {
    color: #fff;
    margin: 0;
    cursor: pointer;
}

.logo-img {
    height: 45px;
}

.content-container {
    padding: 50px;
    min-height: calc(100vh - 64px);
    overflow-y: auto;
}